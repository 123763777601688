<template>
  <div id="ProductDetails" v-loading="loading" style="min-height: 100%">
    <div>
      <HomePageTop ref="pagetop"></HomePageTop>
      <!-- {{details}} -->
      <loginDialog ref="dialog"/>
      <!-- {{ details }} -->
      <div v-if="JSON.stringify(details)!='{}'" :style="details.fromPlatform==1688?'margin-top:0':'margin-top: -32px'"
           class="main">
        <header v-if="details.fromPlatform==1688">
          <div class="headerLeft">
            <img class="logo" src="../../assets/1688/1688logo.svg">
            <img class="headPortrait" src="../../assets/1688/shopHeadPortrait.png">
            <div>
              <div class="shopName cursorPointer" @click="shopGoodsPageJump">
                {{ details.shopName }}
              </div>
              <el-popover placement="bottom" trigger="hover">
                <div>
                  <div class="rateContainer">
                    <div class="text">品質評点</div>
                    <el-rate
                        v-model="details.sellerDataInfo.offerExperienceScore"
                        disabled
                        text-color="#ff9900">
                    </el-rate>
                  </div>
                  <div class="rateContainer">
                    <div class="text">物流レ評点</div>
                    <el-rate
                        v-model="details.sellerDataInfo.logisticsExperienceScore"
                        disabled
                        text-color="#ff9900">
                    </el-rate>
                  </div>
                  <div class="rateContainer">
                    <div class="text">トラブル解決</div>
                    <el-rate
                        v-model="details.sellerDataInfo.disputeComplaintScore"
                        disabled
                        text-color="#ff9900">
                    </el-rate>
                  </div>
                  <div class="rateContainer">
                    <div class="text">問い合わせ</div>
                    <el-rate
                        v-model="details.sellerDataInfo.consultingExperienceScore"
                        disabled
                        text-color="#ff9900">
                    </el-rate>
                  </div>
                </div>
                <div slot="reference" class="rateContainer">
                  <div class="text">総合評点</div>
                  <el-rate
                      v-model="details.sellerDataInfo.compositeServiceScore"
                      disabled
                      text-color="#ff9900">
                  </el-rate>
                </div>
              </el-popover>
            </div>
            <div>
              <div class="flexAndCenter">
                <button v-if="!shopFavoriteStatus"
                        :style="details.offerIdentities==undefined||(details.offerIdentities.indexOf('super_factory')==-1||details.offerIdentities.indexOf('powerful_merchants')==-1)?'margin: -14px 0 0 12px;':''"
                        class="toFavorite" @click="shopAddFavorite">
                  <img alt="" src="../../assets/1688/collect.png">
                  {{ $fanyi("收藏") }}
                </button>
                <button v-else
                        :style="details.offerIdentities==undefined||(details.offerIdentities.indexOf('super_factory')==-1||details.offerIdentities.indexOf('powerful_merchants')==-1)?'margin: -14px 0 0 12px;':''"
                        class="toFavorite"
                        @click="favoriteShopDelete">
                  <img alt="" src="../../assets/SearchResultsPage/Collected.png" style="width: 16px;height: 16px">
                  {{ $fanyi("取消收藏") }}
                </button>
                <button
                    :style="details.offerIdentities==undefined||(details.offerIdentities.indexOf('super_factory')==-1||details.offerIdentities.indexOf('powerful_merchants')==-1)?'margin: -14px 0 0 12px;':''"
                    style="width: 94px;justify-content: center"
                    @click="shopGoodsPageJump">
                  {{ $fanyi("前往店铺") }}
                </button>
              </div>
              <div v-if="details.offerIdentities!=undefined" class="flexAndCenter" style="margin: 10px 0 0 10px">
                <div v-for="(newItem,newIndex) in details.offerIdentities" :key="newIndex">
                  <div v-if="newItem=='super_factory'" :style="newIndex==0?'margin-right: 6px':''"
                       class="powerfulMerchantsContainer">
                    <img alt="" src="../../assets/1688/powerfulMerchantsIcon.png">
                    <span>スーパーメーカー</span>
                  </div>
                  <div v-if="newItem=='powerful_merchants'" :style="newIndex==0?'margin-right: 6px':''"
                       class="superFactoryContainer">
                    <img alt="" src="../../assets/1688/superFactoryIcon.png">
                    <span>実力商家</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="headerRight">
            <div v-for="(item,index) in salesVolumeRankingList" :key="index" class="rankingItem"
                 @click="$fun.toCommodityDetails(item.goodsId,item.shopType)">
              <img :src="item.imgUrl" alt="">
              <div>TOP{{ index + 1 }}</div>
            </div>
          </div>
        </header>
        <!-- 商品规格 -->
        <div class="contentBackground">
          <div class="content">
            <div class="detailTop">
              <h3 class="u-line">HOME&nbsp;>&nbsp;{{ details.titleT }}</h3>
              <div class="detaiCon">
                <!-- 图片说明 -->
                <pictureFrame/>
                <div class="conRight">
                  <h4>
                    {{ details.titleT }}
                  </h4>
                  <!-- 商品中文标题 -->
                  <h5 style="margin-bottom: 12px">
                    {{ details.titleC }}
                  </h5>
                  <div v-if="$route.query.fromPlatform==1688||details.fromPlatform==1688"
                       class="tradeScoreAndSoldOutContainer">
                    <el-rate
                        v-model="details.tradeScore"
                        disabled
                        text-color="#ff9900">
                    </el-rate>
                    <span class="soldOutLabel">直近３カ月の販売数</span>
                    <span class="soldOut">{{ details.soldOut }}</span>
                    <div v-if="tagInfoList.length>0" class="tagInfoListContainer">
                      <div v-for="(item,index) in tagInfoList" :key="index">{{ $fanyi(item) }}</div>
                    </div>
                  </div>
                  <h6>
                    {{
                      $fanyi(
                          "*根据期间限定减价和批量价格变化的情况下，有用Rakumart不正确表示的情况，不过，因为估价完成时被修正，请那样提交估价。"
                      )
                    }}
                  </h6>
                  <img v-if="details.fromPlatform==1688" alt=""
                       src="../../assets/1688/productDetailsShortPic.png" style="margin-bottom: 10px">
                  <!-- 价格区间 -->
                  <div v-if="details.goodsInfo.priceRanges" class="priceGroup">
                    <div class="newConRightTitle priceGroupTitle">
                      <p>{{ $fanyi("价格") }}</p>
                      <p>{{ $fanyi("起批量") }}</p>
                    </div>
                    <div class="priceCon">
                      <div v-for="(ote, oin) in goodsInfo.priceRanges" :key="oin" class="priceConOpt">
                        <div style="display: flex; align-items: center">
                          <p v-if="ote.priceMin != ote.priceMax" :style="!$fun.isOfficialWeb()?'line-height:35px':''"
                             class="price">
                            <span>{{
                                ote.priceMin
                              }}</span><span class="colorB4272B">元</span>（{{
                              $fun.yenNumSegmentation(((Number(ote.priceMin) * exchangeRate)).toFixed(0))
                            }}円）
                            <s v-if="details.fromPlatform == 163">{{ ote.priceRetailMin }}</s>
                          </p>
                          <p v-if="ote.priceMin != ote.priceMax" :style="!$fun.isOfficialWeb()?'line-height:35px':''"
                             style="padding: 0 5px">~</p>
                          <p :style="!$fun.isOfficialWeb()?'line-height:35px':''" class="price">
                            <span>{{
                                ote.priceMax
                              }}</span><span class="colorB4272B">元</span><span>（{{
                              $fun.yenNumSegmentation(((Number(ote.priceMax) * exchangeRate)).toFixed(0))
                            }}円）</span>
                            <s v-if="details.fromPlatform == 163">{{ ote.priceRetailMax }}</s>
                          </p>
                        </div>
                        <p :style="!$fun.isOfficialWeb()?'line-height:35px':''">≥{{ ote.startQuantity }}&nbsp;{{
                            details.goodsInfo.unit
                          }}</p>
                      </div>
                    </div>
                    <div v-if="echartsShow&&!$fun.isOfficialWeb()" class="chartsShowBtn"
                         @click="goodsPriceTrendSeriesShow=!goodsPriceTrendSeriesShow">
                      {{ goodsPriceTrendSeriesShow == true ? $fanyi("隐藏") : $fanyi("查看趋势图") }}
                    </div>
                  </div>
                  <!-- 运费 -->
                  <div class="shippingFee">
                    <div class="goodsAddress">
                      <label class="newConRightTitle">{{
                          $fanyi("国内配送")
                        }}</label>
                      <span>{{ details.address }}</span>
                    </div>
                    <div class="transportationExpense">
                      <label class="newConRightTitle">{{
                          $fanyi("运费")
                        }}</label>
                      <span>{{ freight + '元から' }}</span>
                    </div>
                  </div>
                  <div :style="goodsPriceTrendSeriesShow==true?'display:block':'display:none'" class="echartsContainer">
                    <div class="echartsLabel">{{ $fanyi("数据最新更新日期") }}：{{ chartsUpdateDate }}</div>
                    <newEcharts v-if="goodsPriceTrendSeriesShow" :option="goodsPriceTrendSeriesOption"/>
                  </div>
                  <!-- 种类 -->
                  <div class="kind">
                    <div v-for="(skuItem, skuindex) in skuProps" :key="skuindex" :class="skuProps.length - 1 != skuindex
                      ? 'newKindCon'
                      : 'kindCon'
                      ">
                      <div :title="skuItem.keyT" class="newConRightTitle" style="min-width: 100px">
                        {{ $fun.trunUpperCase(skuItem.keyT) }}：
                      </div>
                      <div v-if="skuProps.length - 1 != skuindex" class="ConBody">
                        <div v-for="(item, index) in skuItem.valueC" :key="index"
                             :class="{ choiceColor: skuItem.active == index }"
                             class="sizeItem" @click="getColor(skuItem, index)">
                          <el-popover placement="right" trigger="hover">
                            <img :src="item.picUrl" alt="" style="width: 100px;"/>
                            <img v-if="item.picUrl" slot="reference" :src="item.picUrl" alt=""/>
                          </el-popover>
                          <!--                          <img v-if="item.picUrl" :src="item.picUrl" alt="" />-->
                          {{ item.name }}
                        </div>
                      </div>
                      <!-- 多选择商品 -->
                      <div v-else class="allCheck">
                        <div v-show="goodsInventoryOption.length > 0"
                             :style="{ maxHeight: addShow ? '236px' : '20000px' }"
                             class="list">
                          <div v-for="(item, index) in goodsInventoryOption" :key="index">
                            <div :style="index==goodsInventoryOption.length-1?'border-bottom:none':''"
                                 class="item">
                              <!-- 属性 -->
                              <div :class="{ center: !item.picUrl && item.name.length < 30 }" class="title">
                                <el-popover placement="right" trigger="hover">
                                  <img :src="item.picUrl" alt="" style="width: 300px; height: 300px"/>
                                  <img v-if="item.picUrl" slot="reference" :src="item.picUrl" alt=""
                                       @click="getPic(item.picUrl,index)"/>
                                </el-popover>
                                <span :class="{ maxLine: item.name.length > 30 }" :title="item.name">
                                  {{ item.name }}</span>
                              </div>
                              <div class="price">
                                <div>
                                  (￥{{ item.price }}<s>{{ item.retail_price }}</s>)
                                </div>
                                <div>{{ (item.price * exchangeRate).toFixed(0) }}{{ $fanyi("円") }}</div>
                              </div>
                              <!-- 库存 -->
                              <div class="numGroup">
                                在庫 {{ item.amountOnSale }}
                              </div>
                              <!-- 选择数量 -->
                              <div class="num">
                                <el-input-number v-model.number="item.num" :min="0" :step="1" size="small"
                                                 @change="maxNum(item, index)">
                                </el-input-number>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- 下拉 -->
                        <div v-if="showNum > 4" :class="{ top: !addShow }" class="addShow"
                             @click="updateShowNumStatus">
                          <div v-if="addShow" class="showOption">
                            <i class="el-icon-arrow-down"></i>
                          </div>
                          <div v-else class="showOption">
                            <i class="el-icon-arrow-up"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="totalGroup">
                    <div class="selectedGoods">
                      {{ $fanyi("已选商品") }}
                      <span class="redFont">{{ Count }}</span>
                      {{ $fanyi("件") }}
                    </div>
                    <div class="money">
                      <div class="moneyLeft">{{ $fanyi("合计：") }}</div>
                      <div class="moneyCenter">
                        {{
                          $fun.roundFixed(money)
                        }} {{ $fanyi("元") }}
                      </div>
                      <div class="moneyRight">
                        ({{
                          $fun.yenNumSegmentation(Number(Number(money) * Number(exchangeRate)).toFixed(0))
                        }}){{ $fanyi("円") }}
                      </div>
                    </div>
                    <div class="addClassGroup">
                      <button id="start" ref="start" :disabled="isAddCart" class="start" @click="addCart()">
                        <img id="addCart" alt=""/>
                        {{ $fanyi("加入购物车") }}
                      </button>
                    </div>
                  </div>
                  <costEstimation v-if="details.fromPlatform==1688"/>
                  <div class="libraryForSeveral">
                    <div @click="goodPushInventory">商品庫に入る</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="shopDetail" v-loading="shopLoading" :element-loading-text="$fanyi('加载中')" class="detail-box">
          <div class="carouselHeader">
            <div class="categoryContainer">
              <div :class="active==0?'colorB4272B':''" @click="active=0;">同店舗商品</div>
              <div :class="active==1?'colorB4272B':''" @click="active=1;">類似商品</div>
            </div>
            <div v-if="active==1" class="iconBox">
              <div @click="shopCarouselChange('left')">
                <img alt="" src="../../assets/1688/flecha-izquierda.png" style="margin-left: 9px"/>
              </div>
              <div @click="shopCarouselChange('right')">
                <img alt="" src="../../assets/1688/flecha-izquierda-1.png" style="margin-left: 12px"/>
              </div>
            </div>
          </div>
          <div class="recommendSlide">
            <el-carousel v-if="active==0" arrow="never" height="284px" trigger="click">
              <el-carousel-item v-for="(item, index) in shopDetail.goods" :key="index">
                <div class="recommendSlideCon">
                  <div v-for="(otem, ondex) in item" :key="ondex" class="recommendSlideConOpt"
                       @click="$fun.toCommodityDetails(otem.goodsId,otem.shopType)">
                    <img :src="otem.imgUrl" alt=""/>
                    <div>
                      <p class="goodsTitle"><span class="type">1688</span>{{ otem.titleT }}</p>
                      <p class="price">
                        {{ $fun.roundFixed(Number(otem.goodsPrice)) }}元
                        （{{
                          $fun.yenNumSegmentation(Number((Number(otem.goodsPrice) * Number(exchangeRate)).toFixed(0)))
                        }}円）
                      </p>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <el-carousel v-else :autoplay="false" arrow="never" height="284px" trigger="click">
              <el-carousel-item v-for="(item, index) in similarGoodsList" :key="index">
                <div class="recommendSlideCon">
                  <div v-for="(otem, ondex) in item" :key="ondex" class="recommendSlideConOpt"
                       @click="$fun.toCommodityDetails(otem.offerId,1688)">
                    <img :src="otem.imageUrl" alt=""/>
                    <div>
                      <p class="goodsTitle"><span class="type">1688</span>{{ otem.subjectTrans }}</p>
                      <p class="price">
                        {{ $fun.roundFixed(Number(otem.priceInfo.price)) }}元
                        （{{
                          $fun.yenNumSegmentation(Number((Number(otem.priceInfo.price) *
                              Number(exchangeRate)).toFixed(0)))
                        }}円）
                      </p>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div v-if="details.fromPlatform==1688" style="width: 1400px;margin:24px auto; ">
          <img alt="" src="../../assets/1688/productDetailsBanner.png">
        </div>
        <div class="newGoodsDetail">
          <div class="japanInSalesContainer">
            <div class="japanInSalesTitle">
              <div>1688日本市場人気商品</div>
              <img alt="" src="../../assets/1688/hot.gif">
            </div>
            <div>
              <div v-for="(item,index) in goodsList" :key="index" class="japanInSalesGoodContainer"
                   @click="toCommodityDetails(item.goodsId,item.shopType)" @mouseenter="goodsMouseenter(index)"
                   @mouseleave="goodsMouseleave(index)">
                <div class="japanInSalesGoodImg" @click.stop="toCommodityDetails(item.goodsId,item.shopType)">
                  <el-image :src="item.imgUrl" class="biggoodsImg" style="height: 255px;width: 265px;">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                    <div slot="placeholder">
                      <i class="el-icon-loading"></i>
                    </div>
                  </el-image>
                  <div v-show="item.hoverStatus === true" class="findSimilarityContainer"
                       @click.stop="imgSearchGoods(item.imgUrl)">{{ $fanyi("查找相似") }}
                  </div>
                </div>
                <div style="padding: 0 12px">
                  <div :title="item.titleT" class="goodsTitle">
                    <span v-if="item.shopType==1688" class="type">1688</span>
                    {{ item.titleT }}
                  </div>
                  <div class="priceContainer">
                    {{ item.goodsPrice }}{{ $fanyi("元") }}（{{
                      (item.goodsPrice * exchangeRate).toFixed(0)
                    }}{{ $fanyi("円") }}）
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :style="details.fromPlatform==1688?'margin:0 auto 30px':''" class="goodsDetail">
            <div class="downloadImageBtn">
              <el-button icon="el-icon-download" size="mini" type="primary" @click="batchDownloadImage">
                商品画像を一括ダウンロード
              </el-button>
            </div>
            <h4 v-show="goodsInfo.detail.length > 0">{{ $fanyi("商品属性") }}</h4>
            <div v-show="goodsInfo.detail.length > 0" class="property">
              <p v-for="(item, index) in goodsInfo.detail" :key="index">
              <span :title="item.keyT">
                {{ $fun.trunUpperCase(item.keyT) }}:</span><span :title="item.valueT">{{ item.valueT }}</span>
              </p>
            </div>
            <certificate
                v-if="JSON.stringify(details)!='{}'&&details.certificateList!=undefined&&details.certificateList.length>0"/>
            <h4>{{ $fanyi("商品详情") }}</h4>
            <div id="detail" ref="detail">
              <div class="description" v-html="details.description"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-else v-show="!loading" class="notHaveGoods">
        <div class="hintInfo">
          <img src="../../assets/default.png"/>
          <p>
            {{ $fanyi("该商品因库存不足或其他原因已经找不到，请查看其他商品！") }}
          </p>
          <p>
            <button class="backHomePage" @click="$fun.routerToPage('/')">
              {{ $fanyi("返回首页") }}
            </button>
          </p>
        </div>
        <recommendCommodities v-if="loading === false"/>
      </div>
      <Foot></Foot>
    </div>
  </div>
</template>
<script>
import pictureFrame from '@/components/ProductDetails/pictureFrame'
import loginDialog from '@/components/public/loginDialog'
import Foot from "../../components/foot/Foot";
import HomePageTop from "../../components/head/HomePageTop.vue";
import recommendCommodities from './components/recommendCommodities'
import costEstimation from "@/views/order/components/costEstimation.vue";
import certificate from "@/views/order/components/certificate.vue";
import JSZip from "jszip";
import FileSaver from "file-saver";
import {goodsPriceTrendSeriesOption} from '@/utlis/echartsOption.js';
import newEcharts from "./components/echarts.vue";

export default {
  metaInfo: {
    title: "RAKUMARTラクマート",
  },
  data() {
    return {
      addShow: true,
      showNum: 0, // 显示选择条数
      isAddCart: false, //动画显示时使用
      isClick: false, //点击时使用
      datas: {
        goods_id: this.$route.query.goods_id,
        shop_type: this.$route.query.fromPlatform,
        sign: '',
        timestamp: '',
      },
      goodsPriceTrendSeriesOption,
      chartsUpdateDate: '',
      salesVolumeRankingList: [],
      startTimestamp: 0,
      tagInfoList: [],
      endTimestamp: 0,
      active: 0,
      freight: 0,
      maxHeightNumber: 0,
      loading: true,
      shopLoading: true,
      details: {}, //详情
      shopDetail: null, //商店推荐商品
      similarGoodsList: [],
      goodsInfo: {}, //商品属性
      detailsImg: "", // 主图
      detailsImgIndex: 0, // 图片索引
      shopFavoriteStatus: false,
      skuProps: {}, //所有规格数据
      skuPropsChinese: {}, //所有规格数据中文
      addCartSkuList: [],
      money: 0,
      similarGoodsFrom: {
        goodsId: this.$route.query.goods_id,
        page: 1,
        pageSize: 10
      },
      Count: 0, // 总数
      wordCloudShow: false,
      // 选中的商品类别
      goodsStr: "",
      goodsList: [],
      sellerDataInfo: {},
      goodsInventoryOption: [],
      goodsInventoryPriceOption: [],
      colorList: ['#39c1cf', '#4269f5', '#b86bd7', '#f35453', '#FA7414', '#FFAB1E', '#8868D9', '#2AB8E6', '#7585A2'],
      valueList: ['1', '8', '9', '3', '6', '10', '2', '5', '7'],
      goodsFavoriteStatus: false, //商品是否已收藏
      showType: "img", // 大图显示视频还是图片
      dataList: [],
      errorImage: [],
      echartsShow: false,
      goodsPriceTrendSeriesShow: false,
    };
  },
  components: {
    Foot,
    pictureFrame,
    HomePageTop,
    loginDialog,
    recommendCommodities,
    costEstimation,
    certificate,
    newEcharts
  },
  computed: {
    exchangeRate() {
      let exchangeRate = localStorage.getItem('exchangeRate');
      return Number(exchangeRate)
    }
  },
  created() {
    this.productDetails();
    if (this.$fun.isOfficialWeb()) {
      if (this.$route.query.source != undefined && this.$route.query.source == 'link') {
        __bl.sum('链接搜索进入商品详情页点击数');
      }
      if (this.$route.query.source == undefined) {
        __bl.sum('通过其他途径进入商品详情页');
      }
      if (this.$route.query.recommendedGoods != undefined && this.$route.query.recommendedGoods == true) {
        document.addEventListener('visibilitychange', () => {
          // 页面可见时更新开始时间戳
          if (document.visibilityState === 'visible') {
            this.startTimestamp = Date.now();
          }
          // 页面不可见时更新结束时间戳并计算时长
          if (document.visibilityState === 'hidden') {
            this.endTimestamp = Date.now();
            this.calculateStayDuration();
          }
        });
      }
    }
  },
  methods: {
    // 查看商品是否已收藏
    favoriteGoodsCheckIn(data) {
      this.$api.favoriteGoodsCheckIn({
        goods_id: [data.goodsId],
        shop_type: data.fromPlatform
      }).then((res) => {
        if (res.code != 0) return false;
        this.goodsFavoriteStatus = res.data[0].inFavorite;
      });
    },
    calculateStayDuration() {
      if (this.startTimestamp > 0 && this.endTimestamp > 0) {
        __bl.avg('爆品商品详情页停留平均时长统计', (this.endTimestamp - this.startTimestamp) / 1000);
      }
    },
    shopCarouselChange(type) {
      if (type == "left") {
        if (this.similarGoodsFrom.page == 1) return this.$message.error(this.$fanyi('当前是第一页'));
        this.similarGoodsFrom.page--
      } else if (type == "right") {
        this.similarGoodsFrom.page++
      }
      this.shopLoading = true;
      this.getSimilarGoodsSearch()
    },
    // 获取商品详情
    productDetails() {
      this.datas.timestamp = Math.floor(new Date().valueOf() / 1000);
      this.datas.sign = this.$fun.setToolValue(this.datas.timestamp);
      if (localStorage.getItem('japan_user_token') != null) {
        this.datas.outMemberId = this.$fun.setUserID()
      }
      this.$api.goodsParticulars(this.datas).then((res) => {
        if (res.data.certificateList != undefined && res.data.certificateList.length > 0) {
          res.data.certificateList.forEach((item, index) => {
            for (let i = 0; i < item.certificatePhotoList.length; i++) {
              if (item.certificatePhotoList[i].indexOf('https://') == -1) {
                item.certificatePhotoList[i] = 'https://cbu01.alicdn.com/' + item.certificatePhotoList[i];
              }
            }
          })
        }
        this.$api.EuropeKeywordSearchProduct({categoryId: res.data.categoryId}).then((res) => {
          if (res.data.result != undefined && res.data.result.result != undefined) {
            res.data.result.result.forEach((item) => {
              item.hoverStatus = false;
            })
            this.goodsList = res.data.result.result;
          }
        });
        this.$api.hotCate({categoryId: res.data.categoryId}).then((res) => {
          if (res.code != 0 || res.data.length == 0) return
          res.data.forEach((item, index) => {
            this.dataList.push({
              name: item.seKeyword,
              translationName: item.seKeywordTranslation,
              color: this.colorList[index] == undefined ? '#333' : this.colorList[index],
            })
            if (index == res.data.length - 1) {
              this.wordCloudShow = true;
            }
          })
        });
        if (res.data.tagInfoList != undefined) {
          let arr = ['certifiedFactory', 'shipInToday', 'shipIn24Hours', 'isOnePsaleFreePostage', 'shipIn48Hours', 'isOnePsale', 'isOnePsaleFreePost', 'getRate24HLv1', 'getRate24HLv2', 'getRate24HLv3', 'getRate48HLv1', 'getRate48HLv2', 'getRate48HLv3', '1688Selection', 'noReason7DReturn'];
          res.data.tagInfoList.forEach((item) => {
            if (item.value == true && arr.indexOf(item.key) != -1) {
              this.tagInfoList.push(this.$fun.matchingTag(item.key))
            }
          })
        }
        this.loading = false;
        if (res.code != 0) return this.$message.error(res.msg);
        if (res.data.tradeScore != undefined) {
          res.data.tradeScore = Number(res.data.tradeScore)
        }
        this.details = res.data;
        if (res.data.video) {
          this.showType = "video";
        }
        if (res.data.fromPlatform == 1688) {
          this.getChartData();
          this.$api.saleShopName({
            goods_id: this.$route.query.goods_id,
            shop_type: res.data.fromPlatform,
            wangwang: res.data.shopName,
          }).then((res) => {
            if (res.data != '') {
              this.details.shopName = res.data;
              this.$forceUpdate();
              this.favoriteShopCheckIn(this.details.fromPlatform, res.data);
            }
          });
        } else {
          this.echartsShow = false;
          this.favoriteShopCheckIn(res.data.fromPlatform, res.data.shopName);
        }
        this.$api.goodsFreightPredict({
          goods_id: this.$route.query.goods_id,
          shop_type: res.data.fromPlatform,
        }).then((res) => {
          if (res.code != 0) return
          if (res.data.freight != undefined) {
            this.freight = res.data.freight;
          }
        });
        this.goodsInfo = res.data.goodsInfo;
        if (res.data.sellerDataInfo != undefined && this.returnType(res.data.sellerDataInfo) != 'array') {
          Object.keys(res.data.sellerDataInfo).forEach((key) => {
            res.data.sellerDataInfo[key] = Number(res.data.sellerDataInfo[key])
          })
          this.sellerDataInfo = res.data.sellerDataInfo;
        }
        //原生获取商品详情，图片翻译列表
        let japan_user_token = localStorage.getItem('japan_user_token');
        if ((this.$store.state.userInfo != null && japan_user_token != null) && (this.$store.state.userInfo.level.currentLevel.level_name === '定額会員' || this.$store.state.userInfo.level.currentLevel.level_name === 'SVIP')) {
          this.$nextTick(() => {
            // 图片标签
            setTimeout(() => {
              // 让所有的table 表格居中显示
              let tables = document
              .getElementById('detail')
              .querySelectorAll('table')
              tables.forEach((i, ind) => {
                i.style.margin = '0 auto'
              })
              let images = document
              .getElementById('detail')
              .querySelectorAll('img')
              images.forEach((item, ind) => {
                if (item.width > 60 && item.width > 45) {
                  item.addEventListener('mousemove', (e) => {
                    if (!e.target.getAttribute('flag')) {
                      e.target.setAttribute('flag', true)
                      let div = document.createElement('div')
                      let button = document.createElement('button')
                      let loadCss = document.createElement('style')
                      let loadbox = document.createElement('p')
                      let pic = document.createElement('span')
                      div.style.position = 'relative'
                      div.style.display = 'inline-block'
                      loadbox.style.cssText =
                          'display:none; width:0px; height: 0px;background:#313131; opacity: 0.5;position: absolute;top: 0;left: 0;z-index:0; '
                      pic.style.cssText =
                          'display: block; 50px;width:50px;height: 50px;border-radius: 50%;position: absolute;top: 50%;left: 50%;z-index:9990; box-shadow: 3px 3px 0 0px #ff730b; transform: translate(-50%, -50%);animation: loop 1s infinite;-webkit-animation: loop 1s infinite;'
                      div.style.width = e.target.width + 'px'
                      div.style.height = e.target.height + 'px'
                      loadbox.style.width = e.target.width + 'px'
                      loadbox.style.height = e.target.height + 'px'
                      loadbox.style.marginTop = -6 + 'px'
                      loadCss.type = 'text/css'
                      loadCss.innerHTML =
                          '@keyframes loop { 0%{transform: rotate(0deg);} 100%{ transform: rotate(360deg);}'
                      document.head.appendChild(loadCss)
                      button.className = 'translation'
                      button.style.width = '70px'
                      button.style.zIndex = '1'
                      button.style.height = '70px'
                      if (e.target.width > 1300) {
                        button.style.top = '0'
                        button.style.left = '0'
                      } else {
                        button.style.top = '0'
                        button.style.right = '0'
                      }
                      button.style.backgroundImage =
                          'url(' + require('../../assets/translation-1.png') + ') '
                      button.style.position = 'absolute'
                      button.style.backgroundSize = 'cover'
                      e.target.parentNode.replaceChild(div, e.target)
                      e.target.appendChild(button)
                      loadbox.appendChild(pic)
                      div.appendChild(loadbox)
                      button.addEventListener('click', (event) => {
                        event.stopPropagation()
                        event.preventDefault()
                        loadbox.style.display = 'block'
                        button.style.zIndex = '99'
                        let imgurlarr = []
                        imgurlarr.push(event.target.previousElementSibling.src)
                        this.$api.pictureTranslation({
                          pic_url: imgurlarr,
                          language: 'ja',
                        }).then((res) => {
                          if (res.code !== 0) return
                          if (!res.data[0].now_url) return
                          event.target.previousElementSibling.src =
                              res.data[0].now_url
                          event.target.previousElementSibling.onload = (e) => {
                            loadbox.style.display = 'none'
                          }
                          event.target.remove()
                          this.$forceUpdate()
                        })
                      })
                      div.appendChild(e.target)
                      div.appendChild(button)
                    }
                  })
                }
              })
            }, 1000)
          })
        }
        this.dataProcessing();
        this.getShopRecommendedGoods();
        this.getSimilarGoodsSearch();
        this.favoriteGoodsCheckIn(this.details);
      });
    },
    //商品详细鼠标移入
    goodsMouseenter(index) {
      this.goodsList[index].hoverStatus = true;
      this.$forceUpdate();
    },
    getSimilarGoodsSearch() {
      this.$api.similarGoodsSearch(this.similarGoodsFrom).then((res) => {
        this.shopLoading = false;
        if (res.code != 0) return;
        if (res.data.length > 0) {
          let lack = 8 - (res.data.length % 8); //取得店铺商品数离6个的倍数差多少个商品的数量
          //差多少个就从最前面取多少个填充到最后
          for (let i = 0; i < lack; i++) {
            res.data.push(res.data[i]);
          }
          // 将商品详情的商品分成6个一组
          res.data = this.$fun.changeArr(res.data, 8);
          this.similarGoodsList = res.data;
        }
      });
    },
    //商品详细鼠标移出
    goodsMouseleave(index) {
      this.goodsList[index].hoverStatus = false;
      this.$forceUpdate();
    },
    updateShowNumStatus() {
      this.addShow = !this.addShow;
    },
    shopGoodsPageJump() {
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('店铺详情页访问量统计');
      }
      let super_factory_status = undefined;
      let powerful_merchants_status = undefined;
      if (this.details.offerIdentities != undefined) {
        this.details.offerIdentities.indexOf('super_factory') != -1 ? super_factory_status = true : undefined
        this.details.offerIdentities.indexOf('powerful_merchants') != -1 ? powerful_merchants_status = true : undefined
      }
      let href = `/shopGoods?shopId=${this.details.shopId}&wangName=${this.details.wangwang}&shopName=${this.details.shopName}&shopType=${this.details.fromPlatform}${super_factory_status == true ? '&super=true' : ''}${powerful_merchants_status == true ? '&powerful=true' : ''}`;
      window.open(href, "_blank");
    },
    // 获取商品推荐商品
    async getShopRecommendedGoods() {
      await this.$api.getShopRecommendedGoods({
        shop_name: this.details.shopName,
        shop_type: this.details.fromPlatform,
      }).then((res) => {
        this.shopLoading = false;
        if (res.code != 0) return;
        if (res.data.goods != null && res.data.goods.length > 0) {
          this.salesVolumeRankingList = res.data.goods.sort((a, b) => b.sales - a.sales).slice(0, 5);
          let lack = 8 - (res.data.goods.length % 8); //取得店铺商品数离6个的倍数差多少个商品的数量
          //差多少个就从最前面取多少个填充到最后
          for (let i = 0; i < lack; i++) {
            res.data.goods.push(res.data.goods[i]);
          }
          // 将商品详情的商品分成6个一组
          res.data.goods = this.$fun.changeArr(res.data.goods, 8);
          this.shopDetail = res.data;
        }
      });
    },
    returnType(obj) {
      return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();
    },
    //下载商品詳細图片
    batchDownloadImage() {
      var blogTitle = this.details.titleT;
      var zip = new JSZip();
      var detail = zip.folder('detail');
      var main = zip.folder('main');
      var baseList = [];
      var arr = [];
      // 要下载图片的url
      let image = document.getElementById('detail').querySelectorAll('img');
      image.forEach((item) => {
        if (item.getAttribute('src') != null) {
          arr.push({img: item.getAttribute('src'), type: 'detail'})
        }
      })
      this.details.images.forEach((item) => {
        if (item != null) {
          arr.push({img: item, type: 'main'})
        }
      })
      for (let i = 0; i < arr.length; i++) {
        let image = new Image();
        // 解决跨域 Canvas 污染问题
        image.setAttribute("crossOrigin", "anonymous");
        image.onload = function () {
          let canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;
          let context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, image.width, image.height);
          let url = canvas.toDataURL(); // 得到图片的base64编码数据
          canvas.toDataURL("image/png");
          baseList.push(url.substring(22)); // 去掉base64编码前的     data:image/png;base64,
          if (baseList.length === arr.length && baseList.length > 0) {
            for (let k = 0; k < baseList.length; k++) {
              if (arr[k].type == 'detail') {
                detail.file(`${Number(k) + 1}.png`, baseList[k], {base64: true});
              } else {
                main.file(`${Number(k) + 1}.png`, baseList[k], {base64: true});
              }
            }
            zip.generateAsync({type: "blob"}).then(function (content) {
              // see FileSaver.js
              FileSaver.saveAs(content, blogTitle + ".zip");
            });
          }
        };
        image.src = arr[i].img;
      }
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('商品詳細下载图片统计');
      }
    },
    //店铺收藏移出
    favoriteShopDelete() {
      let japan_user_token = localStorage.getItem('japan_user_token');
      if (!this.$store.state.userInfo && japan_user_token == null) {
        this.$refs.dialog.visible = true;
        return
      }
      this.$api.shopDelete({
        shop_type: this.details.fromPlatform,
        shop_name: [this.details.shopName]
      }).then((res) => {
        if (res.code != 0) return false;
        this.$message.success(res.msg);
        this.shopFavoriteStatus = !this.shopFavoriteStatus;
        this.$forceUpdate();
      });
    },
    //店铺添加收藏
    shopAddFavorite() {
      let japan_user_token = localStorage.getItem('japan_user_token');
      if (!this.$store.state.userInfo && japan_user_token == null) {
        this.$refs.dialog.visible = true;
        return
      }
      this.$api.shopAdd({
        shop_type: this.details.fromPlatform,
        shop_id: this.details.shopId,
        shop_name: this.details.shopName
      }).then((res) => {
        if (res.code != 0) return false;
        this.$message.success(res.msg);
        this.shopFavoriteStatus = !this.shopFavoriteStatus;
        this.$forceUpdate();
      });
    },
    //图片搜索
    imgSearchGoods(img) {
      this.$store.commit("getsearchImg", img);
      window.open("/CommoditySearch?type=imgSearch&imgUrl=" + img);
    },
    //查询某店铺是否已收藏
    favoriteShopCheckIn(type, name) {
      this.$api.shopCheckIn({
        shop_type: type,
        shop_name: [name]
      }).then((res) => {
        if (res.code != 0) return false;
        this.shopFavoriteStatus = res.data[0].inFavorite;
      });
    },
    // 处理数据
    dataProcessing() {
      let goodsInventoryOptionStr = "";
      let pic = '';
      this.details.goodsInfo.specification.forEach((item, specificationIndex) => {
        item.valueC.forEach((items, index) => {
          if (index === 0 && specificationIndex !== this.details.goodsInfo.specification.length - 1) {
            item.active = 0;
            goodsInventoryOptionStr += items.name + "㊖㊎";
            if (items.picUrl !== "") {
              pic = items.picUrl;
            }
          }
          if (specificationIndex === this.details.goodsInfo.specification.length - 1) {
            goodsInventoryOptionStr += `${items.name}`;
            for (let i = 0; i < this.goodsInfo.goodsInventory.length; i++) {
              if (this.goodsInfo.goodsInventory[i].keyC === goodsInventoryOptionStr) {
                if (this.goodsInfo.goodsInventory[i].valueC.length > 0) {
                  this.goodsInfo.goodsInventory[i].valueC[0].name = items.name;
                  if (items.picUrl !== "") {
                    this.goodsInfo.goodsInventory[i].valueC[0].picUrl = items.picUrl;
                  }
                  if (pic !== '') {
                    this.goodsInfo.goodsInventory[i].valueC[0].newPicUrl = pic;
                  }
                  this.goodsInfo.goodsInventory[i].valueC[0].tName = this.details.goodsInfo.specification[specificationIndex].valueC[index].name;
                  this.goodsInventoryOption.push(this.$fun.deepClone(this.goodsInfo.goodsInventory[i].valueC[0]));
                  this.goodsInventoryPriceOption = this.$fun.deepClone(this.goodsInfo.goodsInventory[i].valueC);
                  break;
                }
              }
            }
            goodsInventoryOptionStr = goodsInventoryOptionStr.substr(0, goodsInventoryOptionStr.length - items.name.length);
          }
        });
      });
      this.goodsInventoryOption.forEach((item) => {
        item.num = 0;
      });
      this.showNum = this.goodsInventoryOption.length;
      this.skuProps = this.details.goodsInfo.specification;
      this.detailsImgIndex = 0;
      this.detailsImg = this.details.images[0];
    },
    // 切换主图
    getPic(pic, index) {
      this.detailsImgIndex = index;
      this.detailsImg = pic;
      this.showType = "img";
    },
    toCommodityDetails(id, type) {
      if (localStorage.getItem('japan_user_token') == null) {
        this.$refs.dialog.visible = true;
        return
      }
      this.$fun.toCommodityDetails(id, type)
    },
    //   图组到底部
    gobottom2() {
      let imgBox = document.querySelector(".images");
      imgBox.scrollTop = 1000;
    },
    //切换展示的内容，勿删
    lookVideo() {
      this.showType = "video";
    },
    // 颜色选择
    getColor(skuItem, index) {
      let goodsInventoryOption = this.$fun.deepClone(this.goodsInventoryOption);
      for (let i = 0; i < goodsInventoryOption.length; i++) {
        for (let j = this.addCartSkuList.length - 1; j >= 0; j--) {
          if (goodsInventoryOption[i].skuId === this.addCartSkuList[j].skuId && goodsInventoryOption[i].num == 0) {
            this.addCartSkuList.splice(j, 1);
          }
        }
      }
      let skuProps = this.$fun.deepClone(this.skuProps);
      let detail = [];
      let valueName = "";
      skuProps.forEach((item, index) => {
        if (index === this.skuProps.length - 1) {
          for (let i = 0; i < goodsInventoryOption.length; i++) {
            if (goodsInventoryOption[i].num > 0) {
              valueName = goodsInventoryOption[i].tName;
              if (detail.length > 0) {
                if (item.keyC === detail[detail.length - 1].key) {
                  detail.pop();
                }
              }
              detail.push({
                key: item.keyC,
                value: index === this.skuProps.length - 1 ? valueName : item.valueC[item.active].name,
              });
              let img = '';
              if (this.goodsInventoryOption[i].picUrl == undefined) {
                if (this.goodsInventoryOption[i].newPicUrl !== '') {
                  img = this.goodsInventoryOption[i].newPicUrl
                } else {
                  img = this.details.images[0];
                }
              } else {
                img = this.goodsInventoryOption[i].picUrl;
              }
              this.addCartSkuList.push({
                shop_id: this.details.shopId,
                shop_name: this.details.shopName,
                goods_id: this.details.goodsId,
                goods_title: this.details.titleC,
                detail: JSON.stringify(detail),
                from_platform: 1688,
                num: this.$fun.deepClone(this.goodsInventoryOption[i].num),
                price: this.$fun.deepClone(this.goodsInventoryOption[i].price),
                pic: img,
                price_ranges: JSON.stringify(this.goodsInfo.priceRanges),
                skuId: this.$fun.deepClone(this.goodsInventoryOption[i].skuId),
                spec_id: this.$fun.deepClone(this.goodsInventoryOption[i].specId)
              })
            }
          }
        } else {
          detail.push({
            key: item.keyC,
            value: item.valueC[item.active].name,
          });
        }
      });
      if (this.addCartSkuList.length > 0) {
        this.addCartSkuList = this.$fun.mergeArrayValue(this.addCartSkuList)
      }
      this.num = 1;
      skuItem.active = index;
      this.$forceUpdate();
      let str = "";
      let goodsInventoryOptionUrl = "";
      let goodsInventoryOptionStr = "";
      this.goodsInventoryOption = [];
      this.details.goodsInfo.specification.forEach((item, specificationIndex) => {
        item.valueC.forEach((items, index) => {
          if (item.active === index && specificationIndex !== this.details.goodsInfo.specification.length - 1) {
            if (items.picUrl !== "") {
              goodsInventoryOptionUrl = items.picUrl;
            }
            goodsInventoryOptionStr += items.name + "㊖㊎";
          }
          if (specificationIndex === this.details.goodsInfo.specification.length - 1) {
            goodsInventoryOptionStr += `${items.name}`;
            for (let i = 0; i < this.goodsInfo.goodsInventory.length; i++) {
              if (this.goodsInfo.goodsInventory[i].keyC === goodsInventoryOptionStr) {
                this.goodsInfo.goodsInventory[i].valueC[0].name = items.name;
                this.goodsInfo.goodsInventory[i].valueC[0].name = items.name;
                if (items.picUrl !== '') {
                  this.goodsInfo.goodsInventory[i].valueC[0].picUrl = items.picUrl;
                  this.goodsInfo.goodsInventory[i].valueC[0].picUrl = items.picUrl;
                } else if (items.picUrl === '' && goodsInventoryOptionUrl !== '') {
                  this.goodsInfo.goodsInventory[i].valueC[0].newPicUrl = goodsInventoryOptionUrl
                  this.goodsInfo.goodsInventory[i].valueC[0].newPicUrl = goodsInventoryOptionUrl;
                }
                this.goodsInfo.goodsInventory[i].valueC[0].tName = this.details.goodsInfo.specification[specificationIndex].valueC[index].name;
                this.goodsInventoryOption.push(this.$fun.deepClone(this.goodsInfo.goodsInventory[i].valueC[0]));
                this.goodsInventoryPriceOption = this.$fun.deepClone(this.goodsInfo.goodsInventory[i].valueC);
                break;
              }
            }
            goodsInventoryOptionStr = goodsInventoryOptionStr.substr(0, goodsInventoryOptionStr.length - items.name.length);
          }
        });
      });
      this.showNum = this.goodsInventoryOption.length;
      if (this.addCartSkuList.length > 0 && this.goodsInventoryOption.length > 0) {
        this.goodsInventoryOption.forEach((item) => {
          for (let i = 0; i < this.addCartSkuList.length; i++) {
            if (item.skuId === this.addCartSkuList[i].skuId) {
              item.num = this.$fun.deepClone(this.addCartSkuList[i].num);
              break;
            } else {
              item.num = 0;
            }
          }
        })
      } else {
        this.goodsInventoryOption.forEach((goodsInventoryOptionItem) => {
          goodsInventoryOptionItem.num = 0;
        });
      }
      str = str.substr(0, str.length - 2);
      this.goodsStr = str;
      // 如果是有图片的选项就替换主图为选项图片
      if (skuItem.valueC[index].picUrl) {
        this.detailsImg = skuItem.valueC[index].picUrl;
        this.showType = "img";
        this.detailsImgIndex = -1;
        this.$forceUpdate();
      }
    },
    // 判断购买数量是否大于在库数量
    maxNum(item, priceIndex) {
      if (item.num == undefined) {
        item.num = 1;
        this.$forceUpdate();
      }
      // if (item.num > item.amountOnSale) {
      //   this.$message.warning(this.$fanyi("购买数量不能超过在库数量"));
      //   return
      // }
      let count = 0;
      let money = 0;
      let skuIdList = [];
      let valueName = '';
      this.skuProps.forEach((skuPropsItem, index) => {
        if (index === this.skuProps.length - 1) {
          valueName += item.name
        } else {
          valueName += skuPropsItem.valueC[skuPropsItem.active].name + '㊖㊎';
        }
      });
      let price = 0;
      this.details.goodsInfo.goodsInventory.forEach((specificationItem) => {
        if (specificationItem.keyC === valueName) {
          specificationItem.valueC.forEach((valueCItem) => {
            if (valueCItem.specId === item.specId && item.num >= Number(valueCItem.startQuantity)) {
              item.price = valueCItem.price;
              price = valueCItem.price;
            }
          });
        }
      });
      this.goodsInventoryOption.forEach((items) => {
        count += items.num;
        money += items.num * items.price;
        skuIdList.push(items.skuId);
      });
      if (this.addCartSkuList.length > 0 && this.addCartSkuList[0] != undefined) {
        this.addCartSkuList.forEach((items) => {
          if (skuIdList.indexOf(items.skuId) === -1) {
            count += items.num;
            money += items.num * items.price;
          }
        })
      }
      this.Count = count;
      this.money = money;
      this.$forceUpdate();
    },
    //添加购物车
    addCart() {
      let japan_user_token = localStorage.getItem('japan_user_token');
      if (!this.$store.state.userInfo && japan_user_token == null) {
        this.$refs.dialog.visible = true;
        return
      }
      if (this.isClick) return false;
      this.isClick = true;
      let traceInfo = {
        isJxhy: this.$route.query.recommendedGoods != undefined && this.$route.query.recommendedGoods == true ? true : false,
        type: this.$route.query.source != undefined ? this.$route.query.source : 'other'
      }
      setTimeout(() => {
        this.isClick = false;
      }, 2000);
      // 未登录时返回登陆页
      let detail = [];
      if (this.$fun.isOfficialWeb()) {
        if (this.$route.query.source == 'index') {
          if (this.$route.query.recommendedGoods == true) {
            __bl.sum('首页爆品商品加入到购物车统计');
          }
          __bl.sum('主页商品加入到购物车统计');
        }
        if (this.$route.query.source != 'index') {
          if (this.$route.query.recommendedGoods == true) {
            __bl.sum('关键词与图搜爆品商品加入到购物车统计');
          }
          switch (this.$route.query.source) {
            case 'link':
              __bl.sum('通过链搜爆品商品加入到购物车统计');
              break;
            case 'img':
              __bl.sum('通过图搜浏览商品加入到购物车统计');
              break;
            case 'keyword':
              __bl.sum('通过词搜浏览商品加入到购物车统计');
              break;
            case 'LandingPage':
              __bl.sum('通过1688跨境选品助手浏览商品加入到购物车统计');
              break;
          }
        }
        if (this.$route.query.source == undefined) {
          __bl.sum('通过其他途径加入到购物车统计');
        }
      }
      let toCartList = [];
      let valueName = "";
      this.skuProps.forEach((item, index) => {
        if (index === this.skuProps.length - 1) {
          for (let i = 0; i < this.goodsInventoryOption.length; i++) {
            if (this.goodsInventoryOption[i].num > 0) {
              valueName = this.goodsInventoryOption[i].tName;
              if (detail.length > 0) {
                if (item.keyC === detail[detail.length - 1].key) {
                  detail.pop();
                }
              }
              detail.push({
                key: item.keyC,
                value: index === this.skuProps.length - 1 ? valueName : item.valueC[item.active].name,
              });
              let img = '';
              if (this.goodsInventoryOption[i].picUrl == undefined) {
                if (this.goodsInventoryOption[i].newPicUrl !== '') {
                  img = this.goodsInventoryOption[i].newPicUrl
                } else {
                  img = this.details.images[0];
                }
              } else {
                img = this.goodsInventoryOption[i].picUrl;
              }
              this.addCartSkuList.push({
                shop_id: this.details.shopId,
                shop_name: this.details.shopName,
                goods_id: this.details.goodsId,
                goods_title: this.details.titleC,
                detail: JSON.stringify(detail),
                from_platform: this.details.fromPlatform,
                num: this.goodsInventoryOption[i].num,
                price: this.goodsInventoryOption[i].price,
                pic: img,
                price_ranges: JSON.stringify(this.goodsInfo.priceRanges),
                skuId: this.goodsInventoryOption[i].skuId,
                spec_id: this.$fun.deepClone(this.goodsInventoryOption[i].specId)
              });
            }
          }
          this.addCartSkuList.forEach((item, index) => {
            if (item == undefined) {
              this.addCartSkuList.splice(index, 1);
            }
          })
          this.addCartSkuList = this.$fun.mergeArrayValue(this.addCartSkuList);
          this.addCartSkuList.forEach((item) => {
            if (item != undefined && item.num > 0) {
              let data = {
                shop_id: this.details.shopId,
                shop_name: this.details.shopName,
                goods_id: this.details.goodsId,
                goods_title: this.details.titleT,
                detail: item.detail,
                from_platform: this.details.fromPlatform,
                num: item.num,
                price: item.price,
                price_ranges: JSON.stringify(this.goodsInfo.priceRanges),
                pic: item.pic != undefined ? item.pic : this.details.images[0],
                trace: JSON.stringify(traceInfo),
                sku_id: item.skuId,
                spec_id: item.spec_id
              }
              toCartList.push(data)
            }
          })
          if (toCartList.length > 0) {
            this.$api.goodsToCart({to_cart: toCartList}).then((res) => {
              if (res.code != 0) return this.$alert(res.msg);
              let img = document.querySelector("#addCart");
              img.src = require("../../assets/cart/cart.gif");
              img.style.display = "block";
              this.isAddCart = true;
              this.Count = 0;
              this.money = 0;
              this.goodsInventoryOption.forEach((item) => {
                item.num = 0;
              })
              this.addCartSkuList.forEach((item) => {
                item.num = 0;
              })
              setTimeout(() => {
                img.src = "";
                img.style.display = "none";
                this.isAddCart = false;
              }, 3000);
              this.$message.success(this.$fanyi("购物车添加成功"));
              this.$store.dispatch("goodsToCartNum");
            });
          } else {
            this.$message.error('必須項目 購入数量')
          }
        } else {
          detail.push({
            key: item.keyC,
            value: item.valueC[item.active].name,
          });
        }
      });
    },
    //添加商品库
    goodPushInventory() {
      let detail = [];
      let arr = [];
      let valueName = "";
      let newArr = this.$fun.deepClone(this.addCartSkuList);
      this.skuProps.forEach((item, index) => {
        if (index === this.skuProps.length - 1) {
          for (let i = 0; i < this.goodsInventoryOption.length; i++) {
            if (this.goodsInventoryOption[i].num > 0) {
              valueName = this.goodsInventoryOption[i].tName;
              if (detail.length > 0) {
                if (item.keyC === detail[detail.length - 1].key) {
                  detail.pop();
                }
              }
              detail.push({
                key: item.keyC,
                value: index === this.skuProps.length - 1 ? valueName : item.valueC[item.active].name,
              });
              let img = '';
              if (this.goodsInventoryOption[i].picUrl == undefined) {
                if (this.goodsInventoryOption[i].newPicUrl !== '') {
                  img = this.goodsInventoryOption[i].newPicUrl
                } else {
                  img = this.details.images[0];
                }
              } else {
                img = this.goodsInventoryOption[i].picUrl;
              }
              newArr.push({
                shop_type: this.details.fromPlatform,
                shop_id: this.details.shopId,
                shop_name: this.details.shopName,
                goods_id: this.details.goodsId,
                goods_title: this.details.titleC,
                img_url: this.$fun.deepClone(img),
                detail: JSON.stringify(this.$fun.deepClone(detail)),
                option: '[]',
                num: this.goodsInventoryOption[i].num,
                price: this.goodsInventoryOption[i].price,
                for_sn: undefined,
                skuId: this.goodsInventoryOption[i].skuId,
                spec_id: this.$fun.deepClone(this.goodsInventoryOption[i].specId),
                note: '',
                inventory_tag: undefined
              });
            }
          }
          newArr.forEach((item, index) => {
            if (item == undefined) {
              newArr.splice(index, 1);
            }
          })
          newArr = this.$fun.mergeArrayValue(newArr);
          newArr.forEach((item) => {
            if (item != undefined && item.num > 0) {
              let data = {
                shop_type: this.details.fromPlatform,
                shop_id: this.details.shopId,
                shop_name: this.details.shopName,
                goods_id: this.details.goodsId,
                goods_title: this.details.titleC,
                img_url: item.img_url != undefined ? item.img_url : this.details.images[0],
                detail: item.detail,
                option: '[]',
                num: item.num,
                price: item.price,
                for_sn: undefined,
                sku_id: item.skuId,
                spec_id: item.spec_id,
                note: '',
                inventory_tag: undefined
              }
              arr.push(data)
            }
          })
          if (arr.length > 0) {
            this.$api.favoriteInventoryBatchSave({data: arr}).then((res) => {
              if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
              this.$message.success(this.$fanyi(res.msg))
            })
          } else {
            this.$message.error('必須項目 購入数量')
          }
        } else {
          detail.push({
            key: item.keyC,
            value: item.valueC[item.active].name,
          });
        }
      });
    },
    //获取商品价格趋势与下单趋势
    getChartData() {
      this.$api.goodsPriceTrend({
        goods_id: this.$route.query.goods_id,
        tracking_time: 3,
      }).then((res) => {
        if (res.code != 0 || res.data.length == 0 || (res.data[0].value.length == 0 && res.data[1].value.length == 0)) return
        this.echartsShow = true;
        let dataValue = [];
        let arr = [];
        let nameList = [];
        this.chartsUpdateDate = res.data[0].value[res.data[0].value.length - 1].date;
        res.data.forEach((item) => {
          let childrenData = [];
          let valueList = [];
          nameList.push(item.type == 'goodsPriceTrend' ? this.$fanyi('价格趋势') : this.$fanyi('下单数量'))
          item.value.forEach((items, indexs) => {
            childrenData.push(items.date)
            valueList.push(items.value)
            if (indexs === item.value.length - 1) {
              arr.push({
                name: item.type == 'goodsPriceTrend' ? this.$fanyi('价格趋势') : this.$fanyi(`下单数量`),
                type: 'line',
                data: valueList
              })
              dataValue = childrenData;
            }
          })
        })
        this.goodsPriceTrendSeriesOption.series = arr;
        this.goodsPriceTrendSeriesOption.xAxis.data = dataValue;
        this.goodsPriceTrendSeriesOption.legend.data = nameList;
      })
    }
  },
};
</script>
<style>
.description img {
  margin-top: -6px !important;
}
</style>
<style lang="scss" scoped>
@import "../../css/mixin";
@import "./css/ProductDetails.scss";

.downloadImageBtn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.superFactoryContainer, .powerfulMerchantsContainer {
  width: 88px;
  height: 24px;
  background: #FFF5F5;
  border-radius: 4px;
  padding: 0 6px;
  display: flex;
  align-items: center;

  img {
    width: 18px;
    height: 17px;
    margin-right: 5px;
  }

  span {
    color: #F72A2B;
    font-size: 12px;
    line-height: normal;
    font-weight: 400;
  }
}

.powerfulMerchantsContainer {
  background: #F8F6FF !important;
  width: 136px !important;

  span {
    color: #3700E1 !important;
  }
}
</style>
